<template>
  <div class="card card-custom card-transparent">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title">Kelembagaan</div>
                  <div class="wizard-desc">Penilaian Kelembagaan</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">2</div>
                <div class="wizard-label">
                  <div class="wizard-title">SDM</div>
                  <div class="wizard-desc">Penilaian SDM</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">3</div>
                <div class="wizard-label">
                  <div class="wizard-title">Sarana dan Prasarana</div>
                  <div class="wizard-desc">Penilaian Sarana dan Prasarana</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">4</div>
                <div class="wizard-label">
                  <div class="wizard-title">Implementasi OSS</div>
                  <div class="wizard-desc">Penilaian OSS</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">5</div>
                <div class="wizard-label">
                  <div class="wizard-title">Keluaran</div>
                  <div class="wizard-desc">Penilaian</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-12 px-0 py-lg-15 px-lg-0">
              <div class="col-xl-12 col-xxl-10">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Kelembagaan PTSP
                          berdasarkan fakta yang ada dengan memilih data yang
                          sesuai. Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file setiap
                          dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Integritas dan kepatuhan</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">1</td>
                            <td rowspan="5">
                              Apakah tersedia SOP Pelayanan Perizinan Berusaha ?
                            </td>
                            <td colspan="2">
                              Untuk menilai ketersediaan SOP Pelayanan Perizinan
                              Berusaha dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP pelayanan perizinan berusaha telah
                                  ditetapkan melalui Keputusan Kepala Daerah
                                  atau Keputusan Kepala DPMPTSP
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a1_file1)"
                                v-if="pm.p1a1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP pelayanan perizinan berusaha mencakup
                                  seluruh bidang usaha (KBLI) yang menjadi
                                  kewenangannya
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a1_file2)"
                                v-if="pm.p1a1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP pelayanan perizinan berusaha dapat diakses
                                  oleh publik secara konvensional atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a1_file3)"
                                v-if="pm.p1a1_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lengkap” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak lengkap” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="3">2</td>
                            <td rowspan="3">Seberapa patuh terhadap NSPK?</td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Penilaian kepatuhan terhadap NSPK, Peraturan
                                  Kepala Daerah (Perkada) yang telah diterbitkan
                                  dengan mengacu pada PP. No. 5 Tahun 2021 dan
                                  PP. No. 6 Tahun 2021. Untuk penetapan bobot
                                  penilaian
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a2_file1)"
                                v-if="pm.p1a2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Patuh” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak patuh” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">3</td>
                            <td rowspan="5">
                              Apakah ada dokumen Standar Pelayanan Publik (SPP)?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya dokumen Standar Pelayanan
                              Publik [SPP] dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Dokumen [SPP] ditetapkan melalui Keputusan
                                  Kepala Daerah atau Keputusan Kepala DPMPTSP
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a3_file1)"
                                v-if="pm.p1a3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Dokumen [SPP] mengacu Permenpan RB No. 15
                                  Tahun 2014 tentang Pedoman Standar Pelayanan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a3_file2)"
                                v-if="pm.p1a3_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Dokumen [SPP] dapat diakses oleh publik secara
                                  konvensional atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a3_file3)"
                                v-if="pm.p1a3_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila ketiga ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">4</td>
                            <td rowspan="4">
                              Apakah ada ketersediaan Pakta Integritas?
                            </td>
                            <td colspan="2">
                              Penilaian ketersediaan Pakta Integritas dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Pakta Integritas tersedia di ruang pelayanan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a4_file1)"
                                v-if="pm.p1a4_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1a4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Pejabat tertinggi yang membidangi pelayanan
                                  perizinan berusaha memiliki Pakta Integritas
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1a4_file2)"
                                v-if="pm.p1a4_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lengkap” apabila kedua
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Kewenangan</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">
                              Apakah ada pelimpahan kewenangan?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya pelimpahan kewenangan
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia dokumen pelimpahan kewenangan sebagai
                                  berikut:
                                </div>
                                <div class="col-1"></div>
                                <div class="col-11">
                                  a. Untuk pemerintah provinsi, pendelegasian
                                  kewenangan oleh gubernur meliputi:
                                </div>
                                <div class="col-1"></div>
                                <div class="col-11">
                                  <div class="row">
                                    <div class="col-1">
                                      <span class="ml-1"> 1) </span>
                                    </div>
                                    <div class="col-11">
                                      Penyelenggaraan Perizinan Berusaha seluruh
                                      KBLI yang menjadi kewenangan pemerintah
                                      daerah provinsi sesuai dengan ketentuan
                                      peraturan perundang-undangan dan
                                    </div>
                                  </div>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-11">
                                  <div class="row">
                                    <div class="col-1">
                                      <span class="ml-1"> 2) </span>
                                    </div>
                                    <div class="col-11">
                                      Penyelenggaraan Perizinan Berusaha seluruh
                                      KBLI yang menjadi kewenangan Pemerintah
                                      Pusat yang dilimpahkan kepada gubernur
                                      berdasarkan asas dekonsentrasi dan tugas
                                      pembantuan.
                                    </div>
                                  </div>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-11">
                                  b. Untuk pemerintah kabupaten/kota,
                                  pendelegasian kewenangan oleh bupati/wali kota
                                  meliputi:
                                </div>
                                <div class="col-1"></div>
                                <div class="col-11">
                                  <div class="row">
                                    <div class="col-1">
                                      <span class="ml-1"> 1) </span>
                                    </div>
                                    <div class="col-11">
                                      Penyelenggaraan Perizinan Berusaha seluruh
                                      KBLI yang menjadi kewenangan Pemerintah
                                      Daerah kabupaten/kota sesuai dengan
                                      ketentuan peraturan perundang-undangan dan
                                    </div>
                                  </div>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-11">
                                  <div class="row">
                                    <div class="col-1">
                                      <span class="ml-1"> 2) </span>
                                    </div>
                                    <div class="col-11">
                                      Penyelenggaraan Perizinan Berusaha seluruh
                                      KBLI yang menjadi kewenangan Pemerintah
                                      Pusat yang dilimpahkan kepada bupati/wali
                                      kota berdasarkan asas tugas pembantuan
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1b1_file1)"
                                v-if="pm.p1b1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p1b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Meliputi seluruh perizinan berusaha yang
                                  menjadi kewenangan pemerintah daerah provinsi,
                                  dan pemerintah daerah kabupaten/kota
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1b1_file2)"
                                v-if="pm.p1b1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada sepenuhnya” apabila kedua
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada sebagian” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="3">2</td>
                            <td rowspan="3">Apakah ada target retribusi?</td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Penilaian terkait ada tidak adanya target
                                  retribusi melalui surat pernyataan Kadis
                                  DPMPTSP terkait
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1b2_file1)"
                                v-if="pm.p1b2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian ada tidaknya target retribusi
                                    dilakukan dengan ketentuan: <br />
                                    1. Nilai “Tidak ada” apabila pemerintah
                                    daerah dapat membuktikan tentang tidak
                                    adanya target retribusi dengan
                                    sekurang-kurangnya melalui surat pernyataan
                                    dari Kepala DPMPTSP.<br />
                                    2. Nilai “Ada” apabila ketentuan di atas
                                    tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Tanggung Jawab</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">Apakah ada mekanisme pengaduan?</td>
                            <td colspan="2">
                              Penilaian ada tidaknya mekanisme pengaduan
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia dokumen SOP layanan pengaduan yang
                                  dapat diakses dengan penggunaan teknologi
                                  informasi dan komunikasi atau secara
                                  konvensional, misalnya:<em>flyer</em>, brosur,
                                  <em>standing banner</em>, dll
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c1_file1)"
                                v-if="pm.p1c1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat layanan pengaduan terjadwal waktu dan
                                  petugasnya
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c1_file2)"
                                v-if="pm.p1c1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Bagaimana tingkat penyelesaian pengaduan?
                            </td>
                            <td>
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1c2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1c2_nilai"
                                  value="25"
                                  >Semua selesai</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1c2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1c2_nilai"
                                  value="10"
                                  >Sebagian selesai</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1c2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1c2_nilai"
                                  value="0"
                                  >Tidak ada yang selesai</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c2_file1)"
                                v-if="pm.p1c2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian ada tidaknya target retribusi
                                    dilakukan dengan ketentuan: <br />
                                    1. Nilai “Semua selesai” apabila jumlah
                                    pengaduan yang masuk sebanding dengan jumlah
                                    pengaduan yang diselesaikan.<br />
                                    2. Nilai “Sebagian selesai” apabila jumlah
                                    pengaduan yang masuk diselesaikan sebagian.
                                    <br />
                                    3. Nilai “Tidak ada yang selesai” apabila
                                    tidak ada pengaduan yang telah diselesaikan
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">3</td>
                            <td rowspan="2">
                              Seberapa cepat waktu penyelesaian masalah atau
                              pengaduan?
                            </td>
                            <td>
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1c3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1c3_nilai"
                                  value="25"
                                  >Lebih cepat dari SOP</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1c3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1c3_nilai"
                                  value="10"
                                  >Sesuai SOP</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1c3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1c3_nilai"
                                  value="0"
                                  >Tidak sesuai</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c3_file1)"
                                v-if="pm.p1c3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian kecepatan waktu penyelesaian
                                    masalah atau pengaduan dilakukan dengan
                                    ketentuan: <br />
                                    1. Nilai “lebih cepat dari SOP” apabila
                                    waktu rata-rata penyelesaian masalah atau
                                    pengaduan lebih cepat daripada waktu yang
                                    dimuat dalam SOP.<br />
                                    2. Nilai “Sesuai SOP” apabila waktu
                                    rata-rata penyelesaian masalah atau
                                    pengaduan sama dengan waktu yang dimuat
                                    dalam SOP. <br />
                                    3. Nilai “Tidak sesuai” apabila waktu
                                    rata-rata penyelesaian masalah atau
                                    pengaduan lebih lama daripada waktu yang
                                    dimuat dalam SOP
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">4</td>
                            <td rowspan="5">
                              Apakah ada layanan <em>helpdesk</em>?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya <em>helpdesk</em> dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Layanan <em>helpdesk</em> dapat diakses dengan
                                  menggunakan teknologi informasi dan komunikasi
                                  atau konvensional
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c4_file1)"
                                v-if="pm.p1c4_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  <em>helpdesk</em> secara konvensional memiliki
                                  ruang/loket dan petugas tersendiri.
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c4_file2)"
                                v-if="pm.p1c4_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1c4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Layanan <em>helpdesk</em> diberikan setiap
                                  hari kerja
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1c4_file3)"
                                v-if="pm.p1c4_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila ketiga ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Kesinambungan</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan koordinasi internal?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya kegiatan koordinasi
                              internal dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Kegiatan koordinasi internal dilakukan
                                  sekurang-kurangnya 1 kali dalam 1 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d1_file1)"
                                v-if="pm.p1d1_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d1_file2)"
                                v-if="pm.p1d1_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d1_file3)"
                                v-if="pm.p1d1_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat notula kegiatan koordinasi internal.
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d1_file4)"
                                v-if="pm.p1d1_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d1_file5)"
                                v-if="pm.p1d1_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, terjadwal” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada tidak terjadwal” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Berapa nilai Indeks Kepuasan Masyarakat (IKM)?
                            </td>
                            <td>
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1d2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1d2_nilai"
                                  value="25"
                                  >Di atas 75</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1d2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1d2_nilai"
                                  value="10"
                                  >Di bawah 75</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p1d2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p1d2_nilai"
                                  value="0"
                                  >Tidak ada</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d2_file1)"
                                v-if="pm.p1d2_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Di atas 75” apabila IKM
                                    mendapatkan hasil di atas 75.<br />
                                    2. Nilai “Di bawah 75” apabila IKM
                                    mendapatkan hasil di bawah 75. <br />
                                    3. Nilai “Tidak ada” apabila tidak dilakukan
                                    survei IKM
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">3</td>
                            <td rowspan="5">
                              Bagaimana tingkat kecukupan dan kualitas kegiatan
                              inovasi?
                            </td>
                            <td colspan="2">
                              Penilaian kecukupan dan kualitas kegiatan inovasi
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Kegiatan inovasi tersedia lebih dari 2 bentuk
                                  inovasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d3_file1)"
                                v-if="pm.p1d3_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Seluruh kegiatan inovasi masih berjalan hingga
                                  periode penilaian
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d3_file2)"
                                v-if="pm.p1d3_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat bukti pemanfaatan seluruh kegiatan
                                  inovasi oleh masyarakat
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d3_file3)"
                                v-if="pm.p1d3_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Sangat baik” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Cukup baik” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="8">4</td>
                            <td rowspan="8">
                              Apakah ada ketentuan kegiatan monitoring dan
                              evaluasi?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya kegiatan monitoring dan
                              evaluasi dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi dilakukan
                                  sekurang-kurangnya 1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d4_file1)"
                                v-if="pm.p1d4_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi melibatkan
                                  <em>stakeholder</em> lain
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d4_file2)"
                                v-if="pm.p1d4_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d4_file3)"
                                v-if="pm.p1d4_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d4_file4)"
                                v-if="pm.p1d4_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat notula rapat kegiatan monitoring dan
                                  evaluasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d4_file5)"
                                v-if="pm.p1d4_file5 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d4_file6 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Dokumentasi rapat dalam bentuk foto digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d4_file6)"
                                v-if="pm.p1d4_file6 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, terjadwal” apabila keenam
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada tidak terjadwal” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="6">5</td>
                            <td rowspan="6">
                              Apakah ada evaluasi dan tindak lanjut hasil
                              penilaian kinerja periode sebelumnya?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya evaluasi dan tindak lanjut
                              hasil penilaian kinerja periode sebelumnya
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d5_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d5_file1)"
                                v-if="pm.p1d5_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d5_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d5_file2)"
                                v-if="pm.p1d5_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d5_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat notula rapat kegiatan monitoring dan
                                  evaluasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d5_file3)"
                                v-if="pm.p1d5_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d5_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Dokumentasi rapat dalam bentuk foto digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d5_file4)"
                                v-if="pm.p1d5_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila keempat ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi. <br />
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="6">6</td>
                            <td rowspan="6">
                              Apakah ada laporan kinerja pelayanan investasi?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya laporan berkala kinerja
                              pelayanan investasi dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d6_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Laporan kinerja pelayanan investasi dilakukan
                                  secara berkala sekurang-kurangnya setiap 3
                                  (tiga) bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d6_file1)"
                                v-if="pm.p1d6_file1 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d6_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Laporan kinerja pelayanan investasi
                                  sekurang-kurangnya memuat:
                                  <div class="row">
                                    <div class="col-1">1.</div>
                                    <div class="col-11">
                                      Jumlah perizinan berusaha yang terbit
                                      (untuk seluruh risiko)
                                    </div>
                                    <div class="col-1">2.</div>
                                    <div class="col-11">
                                      Data rencana dan realisasi investasi
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d6_file2)"
                                v-if="pm.p1d6_file2 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d6_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Laporan kinerja pelayanan investasi
                                  sekurang-kurangnya memuat kendala dan solusi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d6_file3)"
                                v-if="pm.p1d6_file3 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p1d6_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Laporan kinerja pelayanan investasi dibuat
                                  secara formal dalam bentuk dokumen laporan
                                  lengkap fisik atau digital, bukan bahan
                                  presentasi dan sejenisnya
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p1d6_file4)"
                                v-if="pm.p1d6_file4 != null"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, berkala” apabila keempat
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak berkala” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <div v-if="pm.catatan_1_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_1_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan SDM PTSP berdasarkan
                          fakta yang ada dengan memilih data yang sesuai.
                          Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file setiap
                          dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="card card-custom">
                      <div class="card-body">
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="5">
                            <div
                              style="
                                background-color: #3699ff;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-1
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Dinas
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-1">
                              <div v-if="kelembagaan.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="lembaga in kelembagaan"
                                      :key="lembaga"
                                    >
                                      <td>{{ lembaga.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#lihatsdm"
                                          @click="getperlembaga(lembaga.id)"
                                        >
                                          <i class="flaticon-eye"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  style="text-align: center"
                                  v-model="pm.p2_kadis_jumlah"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-1" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kadis_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kadis_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kadis_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kadis_serbha"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kadis_serppb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="5">
                            <div
                              style="
                                background-color: #3699ff;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-2
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Bidang Perizinan Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-2">
                              <div v-if="perizinanberusaha.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="pb in perizinanberusaha"
                                      :key="pb"
                                    >
                                      <td>{{ pb.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#lihatsdm"
                                          @click="getperlembaga(pb.id)"
                                        >
                                          <i class="flaticon-eye"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  style="text-align: center"
                                  v-model="pm.p2_kabidpb_jumlah"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-2" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kabidpb_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kabidpb_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kabidpb_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kabidpb_serbha"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kabidpb_serppb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="5">
                            <div
                              style="
                                background-color: #3699ff;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-3
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Seksi Bidang Perizinan Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-3">
                              <div v-if="kepalaseksi.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="ks in kepalaseksi" :key="ks">
                                      <td>{{ ks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#lihatsdm"
                                          @click="getperlembaga(ks.id)"
                                        >
                                          <i class="flaticon-eye"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  style="text-align: center"
                                  v-model="pm.p2_kasiepb_jumlah"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-3" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kasiepb_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kasiepb_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kasiepb_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kasiepb_serbha"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_kasiepb_serppb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="5">
                            <div
                              style="
                                background-color: #3699ff;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-4
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf Front-office Bidang Perizinan Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-4">
                              <div v-if="stafffoizin.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="sfoz in stafffoizin" :key="sfoz">
                                      <td>{{ sfoz.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#lihatsdm"
                                          @click="getperlembaga(sfoz.id)"
                                        >
                                          <i class="flaticon-eye"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  v-model="pm.p2_foffpb_jumlah"
                                  style="text-align: center"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-4" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_foffpb_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_foffpb_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_foffpb_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="pm.p2_foffpb_serbha"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    v-model="pm.p2_foffpb_serppb"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="5">
                            <div
                              style="
                                background-color: #3699ff;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-5
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Back Office</em> Bidang Perizinan
                              Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-5">
                              <div v-if="staffboizin.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="sboz in staffboizin" :key="sboz">
                                      <td>{{ sboz.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#lihatsdm"
                                          @click="getperlembaga(sboz.id)"
                                        >
                                          <i class="flaticon-eye"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  id="input-small"
                                  style="text-align: center"
                                  v-model="pm.p2_boffpb_jumlah"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-5" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    v-model="pm.p2_boffpb_serbpb"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    disabled
                                    v-model="pm.p2_boffpb_pengalaman"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    disabled
                                    v-model="pm.p2_boffpb_seross"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    disabled
                                    v-model="pm.p2_boffpb_serbha"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    disabled
                                    v-model="pm.p2_boffpb_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="5">
                            <div
                              style="
                                background-color: #3699ff;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-6
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Helpdesk</em> Bidang Perizinan Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-6">
                              <div v-if="staffhd.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="staffhd in staffhd"
                                      :key="staffhd"
                                    >
                                      <td>{{ staffhd.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#lihatsdm"
                                          @click="getperlembaga(staffhd.id)"
                                        >
                                          <i class="flaticon-eye"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  style="text-align: center"
                                  id="input-small"
                                  disabled
                                  v-model="pm.p2_hdeskpb_jumlah"
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-6" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_hdeskpb_serbpb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_hdeskpb_pengalaman"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_hdeskpb_seross"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_hdeskpb_serbha"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_hdeskpb_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="5">
                            <div
                              style="
                                background-color: #3699ff;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-7
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Bidang Dalaks
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-7">
                              <div v-if="kepaladalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="kepaladalaks in kepaladalaks"
                                      :key="kepaladalaks"
                                    >
                                      <td>{{ kepaladalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#lihatsdm"
                                          @click="
                                            getperlembaga(kepaladalaks.id)
                                          "
                                        >
                                          <i class="flaticon-eye"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  type="number"
                                  v-model="pm.p2_kabiddal_jumlah"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-7" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    v-model="pm.p2_kabiddal_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    v-model="pm.p2_kabiddal_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    v-model="pm.p2_kabiddal_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_kabiddal_serbha"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_kabiddal_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="5">
                            <div
                              style="
                                background-color: #3699ff;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-8
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Seksi Bidang Dalaks
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-8">
                              <div v-if="ksdalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="ksdalaks in ksdalaks"
                                      :key="ksdalaks"
                                    >
                                      <td>{{ ksdalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#lihatsdm"
                                          @click="getperlembaga(ksdalaks.id)"
                                        >
                                          <i class="flaticon-eye"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  v-model="pm.p2_kasiedal_jumlah"
                                  type="number"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-8" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_kasiedal_serbpb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_kasiedal_pengalaman"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_kasiedal_seross"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_kasiedal_serbha"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_kasiedal_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="5">
                            <div
                              style="
                                background-color: #3699ff;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-9
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf Front-office Bidang Dalaks
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-9">
                              <div v-if="sfodalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="sfodalaks in sfodalaks"
                                      :key="sfodalaks"
                                    >
                                      <td>{{ sfodalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#lihatsdm"
                                          @click="getperlembaga(sfodalaks.id)"
                                        >
                                          <i class="flaticon-eye"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  v-model="pm.p2_foffdal_jumlah"
                                  type="number"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-9" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_foffdal_serbpb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_foffdal_pengalaman"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_foffdal_seross"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_foffdal_serbha"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    id="input-small"
                                    disabled
                                    v-model="pm.p2_foffdal_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="5">
                            <div
                              style="
                                background-color: #3699ff;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-10
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Back Office</em> Bidang Dalaks
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-10">
                              <div v-if="sbodalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="sbodalaks in sbodalaks"
                                      :key="sbodalaks"
                                    >
                                      <td>{{ sbodalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#lihatsdm"
                                          @click="getperlembaga(sbodalaks.id)"
                                        >
                                          <i class="flaticon-eye"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  v-model="pm.p2_boffdal_jumlah"
                                  type="number"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-10" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_boffdal_serbpb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_boffdal_pengalaman"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_boffdal_seross"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_boffdal_serbha"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_boffdal_serppb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="5">
                            <div
                              style="
                                background-color: #3699ff;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-11
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Helpdesk</em> Bidang Dalaks
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-11">
                              <div v-if="shddalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="shddalaks in shddalaks"
                                      :key="shddalaks"
                                    >
                                      <td>{{ shddalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#lihatsdm"
                                          @click="getperlembaga(shddalaks.id)"
                                        >
                                          <i class="flaticon-eye"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  v-model="pm.p2_hdeskdal_jumlah"
                                  type="number"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-11" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_hdeskdal_serbpb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_hdeskdal_pengalaman"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_hdeskdal_seross"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_hdeskdal_serbha"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="pm.p2_hdeskdal_serppb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <div v-if="pm.catatan_2_surveyor != null">
                          <div class="card text-white bg-primary mb-3">
                            <!-- <div class="card-header">Header</div> -->
                            <div class="card-body">
                              <h5 class="card-title">
                                <i
                                  class="fa fa-info-circle"
                                  style="color: white"
                                  aria-hidden="true"
                                ></i>
                                Catatan
                              </h5>
                              <hr />
                              <div
                                class="card-text"
                                v-html="pm.catatan_2_surveyor"
                              ></div>
                              <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                            </div>
                          </div>
                        </div>
                        <!-- <button type="button" style="float: right;" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">Save</button> -->
                        <!--end: Datatable-->
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Sarana dan Prasarana
                          PTSP berdasarkan fakta yang ada dengan memilih data
                          yang sesuai. Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file setiap
                          dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b
                                >Sarana dan prasarana utama sistem pelayanan
                                perizinan</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">1</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kelayakan ruang front
                              office ?
                            </td>
                            <td colspan="2">
                              Penilaian kecukupan dan kelayakan ruang front
                              office dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia ruangan/loket/meja dan petugas
                                  tersendiri.
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a1_file1)"
                                v-if="pm.p3a1_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Ruang <em>front office</em> memanfaatkan
                                  teknologi informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a1_file2)"
                                v-if="pm.p3a1_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Ruang <em>front office</em> terawat dan
                                  memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a1_file3)"
                                v-if="pm.p3a1_file3 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">2</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang
                              <em>front office</em> ?
                            </td>
                            <td colspan="2">
                              Penilaian kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang <em>front office</em>
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <em>front office</em> memiliki jumlah yang
                                  memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a2_file1)"
                                v-if="pm.p3a2_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <em>front office</em> memanfaatkan teknologi
                                  informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a2_file2)"
                                v-if="pm.p3a2_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <em>front office</em> berfungsi dan layak
                                  digunakan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a2_file3)"
                                v-if="pm.p3a2_file3 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">3</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kelayakan ruang
                              <em> <em> back office </em> </em>?
                            </td>
                            <td colspan="2">
                              Penilaian kecukupan dan kelayakan ruang
                              <em> back office </em> dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia ruang dan petugas tersendiri untuk
                                  <em>
                                    <em> <em> back office </em> </em>
                                  </em>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a3_file1)"
                                v-if="pm.p3a3_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Ruang
                                  <em>
                                    <em> <em> back office </em> </em>
                                  </em>
                                  memanfaatkan teknologi informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a3_file2)"
                                v-if="pm.p3a3_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Ruang
                                  <em>
                                    <em> <em> back office </em> </em>
                                  </em>
                                  terawat dan memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a3_file3)"
                                v-if="pm.p3a3_file3 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu ketentuan di atas tidak
                                    terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">4</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang
                              <em>
                                <em> <em> back office </em> </em> </em
                              >?
                            </td>
                            <td colspan="2">
                              Penilaian kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang
                              <em>
                                <em> <em> back office </em> </em>
                              </em>
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <em>
                                    <em> <em> back office </em> </em>
                                  </em>
                                  memiliki jumlah yang memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a4_file1)"
                                v-if="pm.p3a4_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <em>
                                    <em> <em> back office </em> </em>
                                  </em>
                                  memanfaatkan teknologi informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a4_file2)"
                                v-if="pm.p3a4_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3a4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <em>
                                    <em> <em> back office </em> </em>
                                  </em>
                                  berfungsi dan layak digunakan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3a4_file3)"
                                v-if="pm.p3a4_file3 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu ketentuan di atas tidak
                                    terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Sarana dan prasarana pendukung</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Bagaimana kecukupan dan kelayakan ruang pendukung
                              (ruang rapat, ruang rapat prioritas, ruang
                              pelayanan mandiri)?
                            </td>
                            <td colspan="2">
                              Penilaian kecukupan dan kelayakan ruang pendukung
                              (ruang rapat, ruang pelayanan prioritas, ruang
                              pelayanan mandiri) dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">Tersedia ruang rapat</div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file1)"
                                v-if="pm.p3b1_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia ruang pelayanan prioritas
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file2)"
                                v-if="pm.p3b1_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia ruang pelayanan mandiri
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file3)"
                                v-if="pm.p3b1_file3 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia sarana teknologi informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file4)"
                                v-if="pm.p3b1_file4 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Ruangan pendukung terawat dan memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b1_file5)"
                                v-if="pm.p3b1_file5 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Cukup dan layak” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="5">2</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kelayakan ruang pendukung
                              (ruang rapat, ruang rapat prioritas, ruang
                              pelayanan mandiri)?
                            </td>
                            <td colspan="2">
                              Penilaian kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang pendukung (meja,
                              kursi, komputer, <em>printer</em>, pendukung yang
                              relevan) dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  pendukung memiliki jumlah yang memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b2_file1)"
                                v-if="pm.p3b2_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  pendukung memanfaatkan teknologi informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b2_file2)"
                                v-if="pm.p3b2_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p3b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  pendukung berfungsi dan layak digunakan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b2_file3)"
                                v-if="pm.p3b2_file3 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu ketentuan di atas tidak
                                    terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Bagaimana kecukupan dan kelayakan sarana informasi
                              (Identitas kantor, petunjuk arah, informasi
                              pelayanan)?
                            </td>
                            <td colspan="2">
                              Penilaian sarana informasi (Identitas kantor,
                              petunjuk arah dan informasi pelayanan) dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia identitas kantor
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file1)"
                                v-if="pm.p3b3_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia petunjuk arah dan ruangan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file2)"
                                v-if="pm.p3b3_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia informasi jenis-jenis pelayanan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file3)"
                                v-if="pm.p3b3_file3 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Tersedia sarana teknologi informasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file4)"
                                v-if="pm.p3b3_file4 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="pm.p3b3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </div>
                                <div class="col-11">
                                  Sarana informasi terawat dan memadai
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p3b3_file5)"
                                v-if="pm.p3b3_file5 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Cukup dan layak” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-if="pm.catatan_3_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_3_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div v-if="pm.catatan_4_surveyor != null">
                      <b-alert show variant="danger"
                        >Terdapat catatan, silahkan lihat catatan
                        dibawah!</b-alert
                      >
                    </div>
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Impelementasi OSS
                          berdasarkan fakta yang ada dengan memilih data yang
                          sesuai. Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file setiap
                          dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b
                                >Ketersediaan Standar Operasional
                                Prosedur(SOP)</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah tersedia SOP implementasi OSS?
                            </td>
                            <td colspan="2">
                              Penilaian ketersediaan SOP implementasi OSS
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP ditetapkan melalui Peraturan Kepala Daerah
                                  atau Peraturan Kepala DPMPTSP
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4a1_file1)"
                                v-if="pm.p4a1_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP merupakan ketentuan mengenai tata kelola
                                  penggunaan sistem OSS dalam pelayanan dan
                                  pengawasan perizinan berusaha sesuai
                                  kewenangannya
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4a1_file2)"
                                v-if="pm.p4a1_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4a1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP secara jelas mengatur peran OPD Teknis
                                  sebagai verifikator dan pengawas perizinan
                                  berusaha dan DPMPTSP sebagai pemberi
                                  persetujuan dan koordinator pengawasan
                                  perizinan berusaha
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4a1_file3)"
                                v-if="pm.p4a1_file3 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4a1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP mencakup seluruh bidang usaha (KBLI) yang
                                  menjadi kewenangannya
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4a1_file4)"
                                v-if="pm.p4a1_file4 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4a1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  SOP dapat diakses oleh publik secara digital
                                  atau konvensional
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4a1_file5)"
                                v-if="pm.p4a1_file5 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila kelima ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Implementasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan sosialisasi SOP implementasi
                              OSS ?
                            </td>
                            <td colspan="2">
                              Penilaian ada tidaknya kegiatan sosialisasi SOP
                              implementasi OSS dilakukan dengan ketentuan
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Sosialisasi SOP implementasi OSS dilakukan
                                  sekurang-kurangnya 1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4b1_file1)"
                                v-if="pm.p4b1_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4b1_file2)"
                                v-if="pm.p4b1_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4b1_file3)"
                                v-if="pm.p4b1_file3 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia notula sosialisasi SOP implementasi
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4b1_file4)"
                                v-if="pm.p4b1_file4 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4b1_file5)"
                                v-if="pm.p4b1_file5 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">2</td>
                            <td rowspan="5">
                              Apakah ada kegiatan terjadwal pendampingan
                              implementasi OSS?
                            </td>
                            <td colspan="2">
                              Penilaian kecukupan kegiatan pendampingan
                              implementasi OSS dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Kegiatan pendampingan implementasi OSS
                                  dilakukan sekurang-kurangnya 1 kali dalam 1
                                  bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4b2_file1)"
                                v-if="pm.p4b2_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia surat penugasan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4b2_file2)"
                                v-if="pm.p4b2_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia dokumentasi kegiatan dalam bentuk
                                  laporan atau foto kegiatan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4b2_file3)"
                                v-if="pm.p4b2_file3 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, terjadwal” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak terjadwal” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">3</td>
                            <td rowspan="2">
                              Bagaimana kinerja/hasil implementasi OSS?
                            </td>
                            <td>
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p4b3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="pm.p4b3_nilai"
                                  value="25"
                                  >Semua Perizinan Berusaha menggunakan
                                  OSS</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p4b3_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="pm.p4b3_nilai"
                                  value="10"
                                  >Sebagian Perizinan Berusaha menggunakan
                                  OSS</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4b3_file1)"
                                v-if="pm.p4b3_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian kinerja/hasil implementasi OSS
                                    dilakukan dengan ketentuan dapat menunjukkan
                                    permohonan pelayanan perizinan dalam sistem
                                    OSS. Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Semua Perizinan Berusaha
                                    menggunakan OSS” apabila seluruh permohonan
                                    perizinan berusaha yang masuk sesuai
                                    lampiran PP No. 5 Tahun 2021 yang menjadi
                                    kewenangannya dapat ditunjukkan di sistem
                                    OSS.<br />
                                    2. Nilai “Sebagian Perizinan Berusaha
                                    menggunakan OSS” apabila sebagian permohonan
                                    perizinan berusaha yang masuk sesuai
                                    lampiran PP No. 5 Tahun 2021 yang menjadi
                                    kewenangannya dapat ditunjukkan di sistem
                                    OSS.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="5">4</td>
                            <td rowspan="5">
                              Apakah ada verifikasi perizinan berusaha dengan
                              risiko Menengah Tinggi (MT) dan Tinggi (T)?
                            </td>
                            <td colspan="2">
                              Penilaian adanya verifikasi perizinan berusaha
                              dengan risiko Menengah Tinggi (MT) dan Tinggi (T)
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Pemerintah daerah melakukan verifikasi
                                  terhadap seluruh perizinan berusaha dengan
                                  risiko Menengah Tinggi (MT) dan Tinggi (T)
                                  yang diterima dari pelaku usaha
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4b4_file1)"
                                v-if="pm.p4b4_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p4b4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tidak ada dokumen terkait permohonan perizinan
                                  berusaha secara fisik yang wajib diserahkan
                                  oleh pelaku usaha kepada PTSP
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p4b4_file2)"
                                v-if="pm.p4b4_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Sebagian” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-if="pm.catatan_4_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_4_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Step 5-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Keluaran PTSP
                          berdasarkan fakta yang ada dengan memilih data yang
                          sesuai. Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file setiap
                          dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Realisasi target investasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="3">1</td>
                            <td rowspan="3">
                              Bagaimana tingkat realisasi target investasi?
                            </td>
                            <td rowspan="2">
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p5a1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p5a1_nilai"
                                  value="25"
                                  >Terlampaui atau tercapai</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p5a1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p5a1_nilai"
                                  value="10"
                                  >Tidak tercapai</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p5a1_file1)"
                                v-if="pm.p5a1_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr colspan="4">
                            <td>
                              <button
                                @click="unduhFile(pm.p5a1_file2)"
                                v-if="pm.p5a1_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div
                                    class="row"
                                    style="text-align: justify; color: white"
                                  >
                                    <div class="col-12">
                                      Penilaian tingkat realisasi target
                                      investasi dilakukan dengan ketentuan:
                                    </div>
                                    <div class="col-1">1.</div>
                                    <div class="col-11">
                                      Memiliki dan dapat menunjukkan data target
                                      investasi
                                    </div>
                                    <div class="col-1">2.</div>
                                    <div class="col-11">
                                      Memiliki dan dapat menunjukkan data
                                      tingkat realisasi investasi
                                    </div>
                                  </div>
                                  <div
                                    class="row"
                                    style="text-align: justify; color: white"
                                  >
                                    <div class="col-12">
                                      Untuk penetapan bobot penilaian:
                                    </div>
                                    <div class="col-1">1.</div>
                                    <div class="col-11">
                                      Nilai “Terlampaui atau tercapai” apabila
                                      tingkat realisasi investasi melebihi atau
                                      mencapai target yang ditetapkan.
                                    </div>
                                    <div class="col-1">2.</div>
                                    <div class="col-11">
                                      Nilai “Tidak tercapai” apabila tingkat
                                      realisasi investasi tidak mencapai target
                                      yang ditetapkan.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Bagaimana tingkat penyerapan dan pemanfaatan Dana
                              Alokasi Khusus (DAK)?
                            </td>
                            <td>
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p5a2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p5a2_nilai"
                                  value="25"
                                  >80 – 100%</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p5a2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p5a2_nilai"
                                  value="20"
                                  >60 – 79,99%</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p5a2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p5a2_nilai"
                                  value="10"
                                  >40 – 59,99%</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p5a2_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="p5a2_nilai"
                                  value="5"
                                  >di bawah 40%</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p5a2_file1)"
                                v-if="pm.p5a2_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian tingkat penyerapan dan pemanfaatan
                                    Dana Alokasi Khusus (DAK) dilakukan dengan
                                    ketentuan memiliki dan dapat menunjukkan
                                    dokumen penyerapan dan pemanfaatan DAK.
                                    Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “80-100%” apabila penyerapan dan
                                    pemanfaatan DAK sekurang-kurangnya sebesar
                                    80% dari yang ditetapkan.<br />
                                    2. Nilai “60–79,99%” apabila penyerapan dan
                                    pemanfaatan DAK sekurang-kurangnya sebesar
                                    60% dan sebanyak-banyaknya sebesar 79,99%
                                    dari yang ditetapkan. <br />
                                    3. Nilai “40–59,99%” apabila penyerapan dan
                                    pemanfaatan DAK sekurang-kurangnya sebesar
                                    40% sebanyak-banyaknya sebesar 59,99% dari
                                    yang ditetapkan. <br />
                                    4. Nilai “di bawah 40%” apabila penyerapan
                                    dan pemanfaatan DAK sebanyak-banyaknya
                                    sebesar 40% dari yang ditetapkan.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Realisasi target penyerapan tenaga kerja</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="3">1</td>
                            <td rowspan="3">
                              Bagaimana realisasi target penyerapan tenaga
                              kerja?
                            </td>
                            <td rowspan="2">
                              <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio
                                  disabled
                                  v-model="pm.p5b1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="some-radios"
                                  value="25"
                                  >Terlampaui atau tercapai</b-form-radio
                                >
                                <b-form-radio
                                  disabled
                                  v-model="pm.p5b1_nilai"
                                  :aria-describedby="ariaDescribedby"
                                  name="some-radios"
                                  value="10"
                                  >Tidak tercapai</b-form-radio
                                >
                              </b-form-group>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p5b1_file1)"
                                v-if="pm.p5b1_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr colspan="4">
                            <td>
                              <button
                                @click="unduhFile(pm.p5b1_file2)"
                                v-if="pm.p5b1_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <div
                                    class="row"
                                    style="text-align: justify; color: white"
                                  >
                                    <div class="col-12">
                                      Penilaian tingkat realisasi target
                                      investasi dilakukan dengan ketentuan:
                                    </div>
                                    <div class="col-1">1.</div>
                                    <div class="col-11">
                                      Memiliki dan dapat menunjukkan data target
                                      tenaga kerja
                                    </div>
                                    <div class="col-1">2.</div>
                                    <div class="col-11">
                                      Memiliki dan dapat menunjukkan data
                                      tingkat realisasi penyerapan tenaga kerja
                                    </div>
                                  </div>
                                  <div
                                    class="row"
                                    style="text-align: justify; color: white"
                                  >
                                    <div class="col-12">
                                      Untuk penetapan bobot penilaian:
                                    </div>
                                    <div class="col-1">1.</div>
                                    <div class="col-11">
                                      Nilai “Terlampaui atau tercapai” apabila
                                      tingkat realisasi investasi melebihi atau
                                      mencapai target yang ditetapkan.
                                    </div>
                                    <div class="col-1">2.</div>
                                    <div class="col-11">
                                      Nilai “Tidak tercapai” apabila tingkat
                                      realisasi investasi tidak mencapai target
                                      yang ditetapkan.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4" style="background-color: #ebedf3">
                              <b>Fasilitasi terhadap Usaha Mikro Kecil (UMK)</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="6">1</td>
                            <td rowspan="6">
                              Apakah ada fasilitasi kemitraan dengan pelaku UMK
                              daerah?
                            </td>
                            <td colspan="2">
                              Penilaian fasilitasi kemitraan dengan pelaku UMK
                              daerah dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p5c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Fasilitasi kemitraan dengan pelaku UMK daerah
                                  dilakukan sekurang-kurangnya 1 kali dalam 3
                                  bulan
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p5c1_file1)"
                                v-if="pm.p5c1_file1 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p5c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Terdapat surat tugas fasilitasi kemitraan
                                  dengan pelaku UMK daerah
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p5c1_file2)"
                                v-if="pm.p5c1_file2 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p5c1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Fasilitasi kemitraan dengan pelaku UMK daerah
                                  melibatkan pihak terkait
                                  (Perbankan/Asosiasi/Pelaku usaha
                                  Menengah-Besar)
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p5c1_file3)"
                                v-if="pm.p5c1_file3 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <di class="col-1">
                                  <i
                                    v-if="pm.p5c1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  ></i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  ></i>
                                </di>
                                <div class="col-11">
                                  Tersedia laporan atau dokumentasi kegiatan
                                  fasilitasi kemitraan dalam bentuk foto/video
                                  digital
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                @click="unduhFile(pm.p5c1_file4)"
                                v-if="pm.p5c1_file4 != null"
                                v-b-tooltip.hover
                                title="Unduh"
                                type="button"
                                size="sm"
                                class="btn btn-success text-uppercase btn-sm"
                              >
                                <i
                                  style="color: white"
                                  class="ml-2 far fa-file-pdf"
                                ></i>
                                Unduh File
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, teragenda” apabila keempat
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-if="pm.catatan_5_surveyor != null">
                        <div class="card text-white bg-primary mb-3">
                          <!-- <div class="card-header">Header</div> -->
                          <div class="card-body">
                            <h5 class="card-title">
                              <i
                                class="fa fa-info-circle"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Catatan
                            </h5>
                            <hr />
                            <div
                              class="card-text"
                              v-html="pm.catatan_5_surveyor"
                            ></div>
                            <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Sebelumnya
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Selesai
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        Selanjutnya
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->

                <!-- modal edit sdm -->
                <div
                  class="modal fade"
                  id="lihatsdm"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLongTitle"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                          Perbaiki data SDM {{ getlembaga.nama_sdm }}
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >Nama SDM <span style="color: red">*</span></label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              disabled
                              v-model="getlembaga.nama_sdm"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >NIP <span style="color: red">*</span></label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              disabled
                              v-model="getlembaga.nip"
                              placeholder="Jika tidak memiliki NIP harap isi dengan angka 01, 02, dst"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-4"
                            >Bagian <span style="color: red">*</span></label
                          >
                          <div class="col-8">
                            <b-form-select
                              disabled
                              v-model="getlembaga.jabatan"
                              :options="bagian"
                            ></b-form-select>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >Pendidikan Terakhir, Bidang Ilmu
                            <span style="color: red">*</span></label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              disabled
                              v-model="getlembaga.pendidikan"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >Lama Bertugas di Bidang Terkait (Tahun)
                            <span style="color: red">*</span></label
                          >
                          <div class="col-lg-8">
                            <input
                              type="number"
                              disabled
                              v-model="getlembaga.lama_bertugas"
                              min="0"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >No. Sertifikat Bidang Perizinan Berusaha</label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              disabled
                              v-model="getlembaga.ser_bpb_dasar"
                              placeholder="Masukkan nomor sertifikat tingkat dasar"
                              class="form-control"
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              v-model="getlembaga.ser_bpb_lanjutan"
                              placeholder="Masukkan nomor sertifikat tingkat lanjutan"
                              class="form-control"
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              v-model="getlembaga.ser_bpb_sektoral"
                              placeholder="Masukkan nomor sertifikat tingkat sektoral"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >No. Sertifikat OSS</label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              disabled
                              v-model="getlembaga.ser_oss"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >No. Sertifikat Bahasa Asing</label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              disabled
                              v-model="getlembaga.ser_bha"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >No. Sertifikat Lain Pendukung Pelayanan Perizinan
                            Berusaha</label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              disabled
                              v-model="getlembaga.ser_ppb"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >Format File Bukti</label
                          >
                          <div class="col-lg-8">
                            <b-button disabled variant="success"
                              >Download</b-button
                            >
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >Unggah File Bukti</label
                          >
                          <div class="col-lg-8">
                            <b-form-file
                              v-model="getlembaga.filename"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                              disabled
                            ></b-form-file>
                            <br />
                            <span
                              >Maksimal ukuran file 500 KB dengan format file
                              *.pdf</span
                            >
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Tutup
                          </button>
                          <!-- <button type="button" @click="editsdm(getlembaga.id)" data-dismiss="modal" class="btn btn-primary">Simpan</button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>
  
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>
  
  
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import axios from "axios";
import VueLoading from "vuejs-loading-plugin";
import Vue from "vue";

Vue.use(VueLoading);

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: "Loading", // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: "rgb(255,255,255)", // set custom background
  classes: ["myclass"], // array, object or string
});

export default {
  name: "Wizard-4",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Detil Pengisian" },
      //   { title: "PTSP" }
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });

    this.loadData();
    this.getdata();
  },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      id_ptsp: localStorage.getItem("idptsp"),
      tahun: new Date().getFullYear(),
      user: JSON.parse(localStorage.getItem("user")),
      bagian: [
        { value: "kadis", text: "Kepala Dinas" },
        { value: "kabidpb", text: "Kepala Bidang Perizinan Berusaha" },
        { value: "kasiepb", text: "Kepala Seksi Bidang Perizinan Berusaha" },
        {
          value: "foffpb",
          text: "Staf Front-office Bidang Perizinan Berusaha",
        },
        { value: "boffpb", text: "Staf Back-office Bidang Perizinan Berusaha" },
        { value: "hdeskpb", text: "Staf Help Desk Bidang Perizinan Berusaha" },
        { value: "kabiddal", text: "Kepala Bidang Dalaks" },
        { value: "kasiedal", text: "Kepala Seksi Bidang Dalaks" },
        { value: "foffdal", text: "Staf Front-office Bidang Dalaks" },
        { value: "boffdal", text: "Staf Back-office Bidang Dalaks" },
        { value: "hdeskdal", text: "Staf Help Desk Bidang Dalaks" },
      ],
      selectedFile: null,
      pm: [],
      sdm: [],
      kelembagaan: [],
      perizinanberusaha: [],
      kepalaseksi: [],
      stafffoizin: [],
      staffboizin: [],
      staffhd: [],
      kepaladalaks: [],
      ksdalaks: [],
      sfodalaks: [],
      sbodalaks: [],
      shddalaks: [],
      getlembaga: [],
      nama_sdm: "",
      nip: "",
      pendidikan: "",
      lama_bertugas: "",
      ser_bpb_dasar: "",
      ser_bpb_lanjutan: "",
      ser_bpb_sektoral: "",
      ser_oss: "",
      ser_bha: "",
      ser_ppb: "",
      jabatan: "",
      filename: "",
    };
  },

  methods: {
    submit: function (e) {
      e.preventDefault();

      this.$router.push({ name: "dashboard" });
    },
    unduhFile(data) {
      window.open(data, "_blank");
    },
    loadData() {
      axios
        .get(
          this.url +
            `/pm_ptsp_pemda_2023?filter=idptsp,=,` +
            this.$route.params.id +
            ";tahun,=," +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.pm = response.data.data[0];
          console.log(this.pm.p1a1_file1);
        });
    },

    getdata() {
      $("#example").DataTable().destroy();
      this.loadsdm();
    },
    initDatatable() {
      setTimeout(() => {
        var t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          order: [[0, "desc"]],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          t.column(0, { search: "applied", order: "applied" })
            .nodes()
            .each(function (cell, i) {
              cell.innerHTML = i + 1;
            });
        }).draw();
      }, 300);
    },

    loadsdm() {
      // tabel sdm bawah
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.$route.params.id +
            `;jabatan,=,kadis`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.kelembagaan = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.$route.params.id +
            `;jabatan,=,kabidpb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.perizinanberusaha = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.$route.params.id +
            `;jabatan,=,kasiepb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.kepalaseksi = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.$route.params.id +
            `;jabatan,=,foffpb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.stafffoizin = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.$route.params.id +
            `;jabatan,=,boffpb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.staffboizin = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.$route.params.id +
            `;jabatan,=,hdeskpb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.staffhd = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.$route.params.id +
            `;jabatan,=,kabiddal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.kepaladalaks = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.$route.params.id +
            `;jabatan,=,kasiedal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.ksdalaks = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.$route.params.id +
            `;jabatan,=,foffdal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.sfodalaks = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.$route.params.id +
            `;jabatan,=,boffdal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.sbodalaks = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.$route.params.id +
            `;jabatan,=,hdeskdal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.shddalaks = response.data.data;
        });

      // tabel sdm atas
      axios
        .get(
          this.url + `/data_sdm_2023?filter=idptsp,=,` + this.$route.params.id,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.sdm = response.data.data;
          this.initDatatable();
        });
    },
    getperlembaga(id) {
      axios
        .get(this.url + `/data_sdm_2023?id=${id}`, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.getlembaga = response.data.data;
          // console.log(this.getlembaga)
        });
    },

    hapussdm(id) {
      axios
        .delete(this.url + `/data_sdm_2023/${id}`, {
          headers: {
            "Content-type": "application/json",
            xth: this.token,
          },
        })
        .then((res) => {
          this.$bvToast.toast("Berhasil Dihapus", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          // console.log(res);
          this.getjumlah();
          return res;
        })
        .catch((err) => {
          // console.log(err)
          this.$bvToast.toast("Gagal Dihapus", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });
    },

    getjumlah(data) {
      axios
        .put(
          this.url + "/pm_ptsp_pemda_2023_final",
          {
            _method: "patch",
            idptsp: this.$route.params.id,
            tahun: 2023,
            form: data,
          },
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((res) => {
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          console.log(res);
          this.getdata();
          this.loadData();
          return res;
        })
        .catch((err) => {
          return err;
        });
    },

    handleSubmit() {
      this.$validator.validateAll("form-1").then((result) => {
        if (result) {
          console.log("tetst");
          this.$loading(true);

          let formData = new FormData();
          formData.append("idptsp", this.$route.params.id);
          formData.append("nama_sdm", this.nama_sdm);
          formData.append("nip", this.nip);
          formData.append("pendidikan", this.pendidikan);
          formData.append("jabatan", this.jabatan);
          formData.append("lama_bertugas", this.lama_bertugas);
          formData.append("ser_bpb_dasar", this.ser_bpb_dasar);
          formData.append("ser_bpb_lanjutan", this.ser_bpb_lanjutan);
          formData.append("ser_bpb_sektoral", this.ser_bpb_sektoral);
          formData.append("ser_oss", this.ser_oss);
          formData.append("ser_bha", this.ser_bha);
          formData.append("ser_ppb", this.ser_ppb);
          formData.append("filename", this.filename);
          axios
            .post(this.url + "/data_sdm_2023", formData, {
              // _method: 'patch',
              headers: {
                xth: this.token,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              this.$loading(false);
              this.$bvToast.toast("Berhasil Disimpan", {
                title: `Success `,
                variant: `success`,
                solid: true,
              });
              this.$nextTick(() => {
                this.$bvModal.hide("modal-prevent-closing");
              });
              // console.log(res);
              this.getjumlah("3");
              return res;
            })
            .catch((err) => {
              this.$loading(false);
              console.log(err);
              this.$bvToast.toast("Gagal Disimpan", {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
              return err;
            });
        }
      });

      // Push the name to submitted names
      // this.submittedNames.push(this.name)
      // Hide the modal manually
    },

    deleteFile(data) {
      axios
        .put(
          this.url + "/pm_ptsp_pemda_2023_delete_lampiran",
          {
            idptsp: this.$route.params.id,
            tahun: 2023,
            field: data,
          },
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((res) => {
          this.$bvToast.toast("Berhasil Dihapus", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          this.loadData();
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Gagal Dihapus", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });
    },

    editsdm(id) {
      let formData = new FormData();
      formData.append("idptsp", this.$route.params.id);
      formData.append("nama_sdm", this.getlembaga.nama_sdm);
      formData.append("nip", this.getlembaga.nip);
      formData.append("pendidikan", this.getlembaga.pendidikan);
      formData.append("jabatan", this.getlembaga.jabatan);
      formData.append("lama_bertugas", this.getlembaga.lama_bertugas);
      formData.append("ser_bpb_dasar", this.getlembaga.ser_bpb_dasar);
      formData.append("ser_bpb_lanjutan", this.getlembaga.ser_bpb_lanjutan);
      formData.append("ser_bpb_sektoral", this.getlembaga.ser_bpb_sektoral);
      formData.append("ser_oss", this.getlembaga.ser_oss);
      formData.append("ser_bha", this.getlembaga.ser_bha);
      formData.append("ser_ppb", this.getlembaga.ser_ppb);
      formData.append("filename", this.getlembaga.filename);
      axios
        .post(this.url + "/data_sdm_2023/" + id, formData, {
          // _method: 'patch',
          headers: {
            xth: this.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$loading(false);
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });
          this.$nextTick(() => {
            this.$bvModal.hide("modal-prevent-closing");
          });
          // console.log(res);
          this.getjumlah("3");
          return res;
        })
        .catch((err) => {
          this.$loading(false);
          console.log(err);
          this.$bvToast.toast("Gagal Disimpan", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });
    },
  },
};
</script>
  
<style scoped>
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 768px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 10px 1rem 1rem 1rem;
  }

  /* tr:nth-child(odd) {
      background: #E1EDFF;
    } */

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    /* padding-left: 50%; */
  }

  th {
    display: none;
  }

  /* td:before { */
  /* Now like a table header */
  /* position: absolute; */
  /* Top/left values mimic padding */
  /* top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap; */
  /* } */

  /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
  /* td:nth-of-type(1):before { content: "No"; }
		td:nth-of-type(2):before { content: "Indikator"; }
		td:nth-of-type(3):before { content: "Status"; }
		td:nth-of-type(4):before { content: "Jawaban"; }
        td:nth-of-type(5):before { content: "Unggah"; } 
        td:nth-of-type(6):before { content: "Download"; }  */
}
</style>